import gql from "graphql-tag";

const GET_TABLE_CHANNELS = gql`
query GetPurchasesTypeChannel($filter: String, $filterSite: String, $offset: Int, $limit: Int, $endDatetime: Int, $startDatetime: Int) {
  campaign(filter: $filter) {
    sites(offset: $offset, limit: $limit, filter: $filterSite) {
      site_id
      site_name
      purchases(start_datetime: $startDatetime, end_datetime: $endDatetime) {
        cpm {
          quantity
          channels
          total_data {
            views
            clicks
            blocklists
            valids
            completes
            viewability
            viewables
            impressions
            denominador_viewability
          }
        }
        cpc {
          quantity
          channels
          total_data {
            views
            clicks
            blocklists
            valids
            completes
            viewability
            viewables
            impressions
            denominador_viewability
          }
        }
        cpv {
          quantity
          channels
          total_data {
            views
            clicks
            blocklists
            valids
            completes
            viewability
            viewables
            impressions
            denominador_viewability
          }
        }
      }
    }
  }
}
`;

const GET_TABLE_PLACEMENTS = gql`
query GetPlacementInChannel($filter: String, $endDatetime: Int, $startDatetime: Int) {
  channel(filter: $filter) {
    placements {
      placement_id
      placement_name
      placement_purchase_qty
      placement_dates
      format {
        format_size_width
        format_size_height
        format_name
      }
      total_data(start_datetime: $startDatetime, end_datetime: $endDatetime) {
        clicks
        blocklists
        valids
        impressions
        views
        completes
        viewability
        viewables
      }
      placement_status {
        placement_status_id
        status_descr
      }
      purchase_type {
        purchase_type_format
        purchase_type_name
      }
    }
  }
}
`;

const GET_TABLE_CREATIVES = gql`
query GetCreativeInPlacement(
  $filter: String
  $startDatetime: Int
  $endDatetime: Int
  $totalDataFilter2: String
) {
  placement(filter: $filter) {
    creatives {
      creative_id
      creative_group {
        group_name
      }
      total_data(
        start_datetime: $startDatetime
        end_datetime: $endDatetime
        filter: $totalDataFilter2
      ) {
        impressions
        clicks
        views
        blocklists
        valids
        starts
        completes
        viewability
        viewables
        unique_impressions_site_cpm
      }
      placement {
        placement_id
        purchase_type {
          purchase_type_format
        }
      }
    }
  }
}
`;

export { GET_TABLE_CHANNELS, GET_TABLE_PLACEMENTS, GET_TABLE_CREATIVES };
