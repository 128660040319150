import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "apollo-boost";

const VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const AUTH_SESSION_ID = "sessionId";

const resetApolloClient = async (apollo) => {
  try {
    await apollo.resetStore();
  } catch (e) {}
};

const onLogin = async (apollo, sessionId) => {
  if (typeof window.localStorage !== "undefined" && sessionId) {
    window.localStorage.setItem(AUTH_SESSION_ID, sessionId);
  }
  await resetApolloClient(apollo);
};

const customFetch = (uri, options) => {
    return fetch(
      `${VUE_APP_API_BASE_URL}?s=YpmpSdpnWMOiBT2QJDFc7gqq3r43sl3D`,
      options
    );
};

const link = new HttpLink({ fetch: customFetch });

const apollo = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.key || null,
  }),
  connectToDevTools: process.env.NODE_ENV !== "production",
});

export default apollo;

export { AUTH_SESSION_ID, onLogin };
