import Vue from "vue";
import Vuex from "vuex";
import header from "./modules/header.module.js";
import dashboard from "./modules/dashboard.module.js";
import auth from "./modules/auth.module.js";
import teamDataModule from "./modules/teamDataModule.module.js";
import campaign from "./modules/campaign.module.js";
import generalDatas from "./modules/generalDatas.module.js";
import midInteractions from "./modules/midInteractions.module.js";
import tablePerDay from "./modules/tablePerDay.module.js";
/* TO-DO 
  precisa corrigir esse arquivo para ter somente modulos e todo o gerenciamento do estado dentro de cada modulo
*/
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {},
    teamData: {},

    inputSearch: {
      text: null,
      site_id: null,
    },

    selectSearch: {
      sites: [],
    },

    tableMap: {},
    dataChartVerification: {},
    domainOverlay: {},
    dataWorld: {},
  },
  mutations: {
    LOGGED_USER(state, payload) {
      state.user = payload;
    },
    LOGOUT_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_INPUT_SEARCH(state, payload) {
      if (payload == null) {
        state.inputSearch.site_id = null;
        state.inputSearch.text = null;
      } else if (typeof payload === "object" && payload !== null) {
        state.inputSearch.site_id = payload.site_id;
      } else {
        state.inputSearch.text = payload;
      }
    },

    SET_VISUAL_IDENTITY(state, payload) {
      state.teamData = payload;
    },
    setTableMap(state, payload) {
      state.tableMap = payload;
    },
    setChartVerification(state, payload) {
      state.dataChartVerification = payload;
    },
    setDomainOverlay(state, payload) {
      state.domainOverlay = payload;
    },
    setDataWorld(state, payload) {
      state.dataWorld = payload;
    },
  },
  actions: {},
  modules: {
    header,
    dashboard,
    header,
    auth,
    teamDataModule,
    campaign,
    generalDatas,
    midInteractions,
    tablePerDay
  },
});

export default store;
