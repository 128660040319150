import Vue from "vue";
import { teamData } from "@/services/index.js";
import Router from "vue-router";
import AuthenticationRoutes from "./auth.js";
import DashboardRoutes from "./dashboard.js";
import AuthService from "@/components/Auth/services/auth-service";

//https://analytics.adx.space
//https://analytics.aheadserver.com.br
//https://analytics.adforce.com.br


let url = window.location.hostname
if(window.location.hostname == 'localhost' || window.location.hostname == 'analytics-v4.adserver.dev'){
    url = 'https://analytics.adx.space'
} else {
    url = window.location.hostname
}


// let url = process.env.VUE_APP_URL;

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/dashboard/campaign/6069",
      name: "home",
      meta: { requiresAuth: false },
    },
    DashboardRoutes,
  ],

  scrollBehavior(to, from, savePosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  try {
    const variables = { filter: `{ 'team_analytics_url' : '${url}' }` };
    const response = await teamData(variables);
    /* TO-DO 
        remover commit direto e deixar somente a chamada da action
    */
    router.app.$store.commit("SET_VISUAL_IDENTITY", response);
    router.app.$store.dispatch("teamDataModule/setTeamData", response);

    document.title = response.team_name + " - " + "Analytics";
  } catch (error) {}
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (to.query.s) {
      localStorage.setItem("sessionId", to.query.s);
    }
    const loginRoute = {
      path: "/",
      query: { redirect: to.fullPath },
    };
    if (localStorage.sessionId) {
      try {
        const user = await AuthService.loggedUser({
          fetchPolicy: "network-only",
        });
        /* TO-DO 
                    remover commit direto e deixar somente a chamada da action
                */
        router.app.$store.commit("LOGGED_USER", user);
        router.app.$store.dispatch("header/getCountUnreadNotification", user?.count_unread_notifications?.count);
        router.app.$store.dispatch("auth/getUser", user);

        window.localStorage.setItem("redirect", user.redirect);
        // if (to.matched.some((route) => route.meta.accessRoute)) {
          if (user.redirect !== null && (user.campaign_id != to.params.campaign_id || user.site_id != to.params.site_id)) {
            window.location.href = localStorage.redirect+'?s='+to.query.s
          } else {
            return next();
          }
        // }
        // return next();
      } catch (error) {
        return next(loginRoute);
      }
    }
    return next(loginRoute);
  }
  return next();
});

export default router;

Vue.use(Router);
