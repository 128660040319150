import { getPurchasesTypesTotal, getPurchasesTypesTotalSite } from "@/services/singleCampaign/index";

const generalDatas = {
  namespaced: true,
  state: {
    purchasesTypes: null,
    allChartVerification: null,
    sensitiveUrl: null

  },
  getters: {
    purchasesTypes: (state) => state.purchasesTypes,
    allChartVerification: (state) => state.allChartVerification,
    sensitiveUrl: (state) => state.sensitiveUrl,
  },
  mutations: {
    SET_PURCHASES_TYPES_TOTAL(state, payload) {
      state.purchasesTypes = payload;
    },
    SET_CHART_VERIFICATION(state, payload) {
      state.allChartVerification = payload;
    },
    CLEAR_CHART_VERIFICATION(state, payload) {
      state.allChartVerification = null;
    },
    SET_SENSITIVE_URL(state, payload) {
      state.sensitiveUrl = payload;
    },
  },
  actions: {
    async purchasesTypesTotalSite({ commit }, variables) {
      const response = await getPurchasesTypesTotalSite(variables);
      commit("SET_PURCHASES_TYPES_TOTAL", response);
    },
    async purchasesTypesTotal({ commit }, variables) {
      const response = await getPurchasesTypesTotal(variables);
      commit("SET_PURCHASES_TYPES_TOTAL", response);
    },
    async clearChartVerificationTotal({ commit }, variables) {
      commit("CLEAR_CHART_VERIFICATION",variables);
    },
  },
};

export default generalDatas;
