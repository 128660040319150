import gql from "graphql-tag";

export const DATA_HEADER_CAMPAING = gql`
  query GetCampaignForHeader($filter: String) {
    campaign(filter: $filter) {
      campaign_id
      campaign_name
      campaign_start_datetime
      campaign_end_datetime
      campaign_status_id
      account {
        account_name
      }
      advertiser {
        account_name
      }
    }
  }
`;
