import Dashboard from "@/views/Dashboard/Dashboard.vue"

export default {
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresAuth: false },
    children: [
        {
            path : 'campaign/:campaign_id',
            name: 'SingleCampaign',
            meta: { accessRoute: true },
            component: () => import('@/views/Dashboard/SingleCampaign.vue'),
        },
    ]
}
